<template>
  <b-overlay class="row" :show="loading">
    <div class="col-12">
      <div class="col-12 col-lg-4 c g text-center">
        <button class="btn btn-relief-primary btn-block btn-lg" @click="save()">
          <i class="fa fa-save"></i>
          حفظ التعديلات
        </button>
      </div>
      <div class="card">
        <div class="card-body">
          <button class="btn btn-link" @click="add()">
            <i class="fa fa-plus"></i>
            إضافة صف جديد
          </button>
          <app-collapse>
            <app-collapse-item
              v-for="c in settings.list"
              :key="c.id"
              :title="c.title"
              :id="'class' + c.id"
            >
              <div class="border card card-body bg-light-primary">
                <div class="form-group" style="max-width: 200px">
                  <label for="">إسم الصف</label>
                  <input
                    type="text"
                    @change="changed()"
                    :class="['form-control', 'c' + c.id]"
                    v-model="c.title"
                  />
                </div>
                <div class="row">
                  <button
                    class="btn btn-link"
                    @click="
                      ca = c;
                      cal = { subjects: { e: 1 }, days: { e: 1 }, count: 1 };
                    "
                    v-b-modal.modal-1
                  >
                    <i class="fa fa-plus"></i>
                    إضافة فصل جديد
                  </button>
                </div>
                <app-collapse>
                  <app-collapse-item
                    v-for="classroom in c.classrooms"
                    :key="classroom.id"
                    :title="classroom.title"
                    :id="'classroom' + classroom.id"
                  >
                    <div class="border card card-body bg-light-secondary">
                      <div
                        class="form-group"
                        style="max-width: 200px"
                        @change="changed()"
                      >
                        <label for="">إسم الفصل</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="classroom.title"
                        />
                      </div>
                      <div class="row">
                        <div class="col-12 col-lg-6 g">
                          <div class="card">
                            <div class="card-header">أنصبة المواد</div>
                            <div class="card-body">
                              <div class="col-12 table-responsive">
                                <table
                                  class="
                                    table table-hover table-bordered
                                    custom-table
                                  "
                                >
                                  <thead>
                                    <th>المادة</th>
                                    <th>عدد الحصص</th>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="subject in subjects"
                                      :key="subject.id"
                                    >
                                      <td>
                                        {{ subject.fullname }}
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          style="width: 50%; border: none"
                                          v-model="
                                            classroom.subjects[subject.id]
                                          "
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-lg-6 g">
                          <div class="card">
                            <div class="card-header">عدد الحصص يومياً</div>
                            <div class="card-body">
                              <div class="col-12 table-responsive">
                                <table
                                  class="
                                    table table-hover table-bordered
                                    custom-table
                                  "
                                >
                                  <thead>
                                    <th>اليوم</th>
                                    <th>عدد الحصص</th>
                                  </thead>
                                  <tbody>
                                    <tr v-for="day in days" :key="day.id">
                                      <td>
                                        {{ day.name }}
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          style="width: 50%; border: none"
                                          v-model="classroom.days[day.code]"
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 text-right">
                        <button
                          class="btn btn-link text-danger"
                          @click="deleteClassroom(c, classroom.id)"
                        >
                          <i class="fa fa-trash"></i>
                          حذف الفصل
                        </button>
                      </div>
                    </div>
                  </app-collapse-item>
                </app-collapse>
                <div class="col-12 text-right">
                  <button
                    class="btn btn-link text-danger"
                    @click="deleteClass(c.id)"
                  >
                    <i class="fa fa-trash"></i>
                    حذف الصف
                  </button>
                </div>
              </div>
            </app-collapse-item>
          </app-collapse>
        </div>
      </div>
    </div>
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      title="اضافة فصول جديدة"
      size="xl"
      ok-only
      ok-title="اغلاق"
    >
      <div class="row">
        <div class="col-12 col-lg-4 g">
          <div class="form-group">
            <h5 for="">عدد الفصول المراد اضافتها</h5>
            <input
              type="number"
              class="form-control form-control-lg"
              v-model="cal.count"
            />
          </div>
        </div>
        <div class="col-12"></div>
        <div class="col-12 col-lg-6 g">
          <div class="card">
            <div class="card-header">أنصبة المواد لكل فصل</div>
            <div class="card-body">
              <div class="col-12 table-responsive">
                <table class="table table-hover table-bordered custom-table">
                  <thead>
                    <th>المادة</th>
                    <th>عدد الحصص</th>
                  </thead>
                  <tbody>
                    <tr v-for="subject in subjects" :key="subject.id">
                      <td>
                        {{ subject.fullname }}
                      </td>
                      <td>
                        <input
                          type="number"
                          style="width: 50%; border: none"
                          v-model="cal.subjects[subject.id]"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 g">
          <div class="card">
            <div class="card-header">عدد الحصص يومياً لكل فصل</div>
            <div class="card-body">
              <div class="col-12 table-responsive">
                <table class="table table-hover table-bordered custom-table">
                  <thead>
                    <th>اليوم</th>
                    <th>عدد الحصص</th>
                  </thead>
                  <tbody>
                    <tr v-for="day in days" :key="day.id">
                      <td>
                        {{ day.name }}
                      </td>
                      <td>
                        <input
                          type="number"
                          style="width: 50%; border: none"
                          v-model="cal.days[day.code]"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 g text-center">
          <button class="btn btn-relief-success btn-lg" @click="addClassroom()">
            إضافة الفصول <i class="fa fa-arrow-left"></i>
          </button>
        </div>
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import {
  BOverlay,
  BFormGroup,
  BFormRadioGroup,
  BModal,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BFormGroup,
    BFormRadioGroup,
    BModal,
    BFormCheckbox,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: true,
      settings: {},
      viewSave: false,
      subjects: [],
      ca: {},
      cal: { subjects: { e: 1 }, days: { e: 1 }, count: 1 },
      user: user,
      days: [
        {
          code: "1",
          name: "الأحد",
          list: {},
        },
        {
          code: "2",
          name: "الاثنين",
          list: {},
        },
        {
          code: "3",
          name: "الثلاثاء",
          list: {},
        },
        {
          code: "4",
          name: "الاربعاء",
          list: {},
        },
        {
          code: "5",
          name: "الخميس",
          list: {},
        },
      ],
    };
  },
  created() {
    var g = this;
    $.post(api + "/table/settings", {
      jwt: user.jwt,
      type: "classes",
    })
      .then(function (r) {
        r = JSON.parse(r);
        g.settings = r;
        g.settings.list = g.settings.list ? g.settings.list : [];
        $.post(api + "/table/settings", {
          jwt: user.jwt,
          type: "subjects",
        })
          .then(function (r) {
            r = JSON.parse(r);
            g.subjects = r;
            g.subjects = g.subjects.list ? g.subjects.list : [];
            g.loading = false;
            if (window.location.href.includes("#")) {
              setTimeout(() => {
                $(
                  "#class" +
                    window.location.href.split("#")[1] +
                    " [aria-expanded]"
                )
                  .first()
                  .click();
                setTimeout(() => {
                  $(
                    "#classroom" +
                      window.location.href.split("#")[2] +
                      " [aria-expanded]"
                  )
                    .first()
                    .click();
                  setTimeout(() => {
                    $(
                      "#classroom" +
                        window.location.href.split("#")[2] +
                        " .card-body"
                    ).css("background", "#CEE5D0");
                    $("html, body").animate(
                      {
                        scrollTop: $(
                          "#classroom" + window.location.href.split("#")[2]
                        ).offset().top,
                      },
                      500
                    );
                    setTimeout(() => {
                      $(
                        "#classroom" +
                          window.location.href.split("#")[2] +
                          " .card-body"
                      ).css("background", "#fff");
                    }, 2000);
                  }, 500);
                }, 100);
              }, 100);
            }
          })
          .catch(function () {
            swal.fire(
              "حدث خطأ اثناء الاتصال بالخادم",
              "برجاء المحاولة مرة أخرى",
              "warning"
            );
            g.loading = false;
          });
      })
      .catch(function () {
        swal.fire(
          "حدث خطأ اثناء الاتصال بالخادم",
          "برجاء المحاولة مرة أخرى",
          "warning"
        );
        g.loading = false;
      });
  },
  methods: {
    add() {
      var g = this,
        a = this.settings.list;
      this.viewSave = true;
      a.push({
        title: "بدون عنوان",
        id: Math.random().toString().split(".")[1],
        classrooms: [],
      });
      this.settings = {
        list: a,
      };
    },
    deleteClassroom(c, id) {
      if (confirm("متأكد من حذف الفصل؟")) {
        var a = [];
        c.classrooms.forEach(function (e) {
          if (e.id != id) {
            a.push(e);
          }
        });
        c.classrooms = a;
        this.changed();
      }
    },
    addClassroom() {
      this.viewSave = true;
      var g = this;
      g.settings.list.forEach(function (a) {
        if (a.id == g.ca.id) {
          var c = a;
          c.classrooms = c.classrooms ? c.classrooms : [];
          for (var i = 1; i <= g.cal.count; i++) {
            c.classrooms.push({
              title: a.title + " " + i,
              id: Math.random().toString().split(".")[1],
              subjects: g.cal.subjects,
              days: g.cal.days,
            });
          }
          $("#modal-1___BV_modal_footer_ > button").click();
          var a = [];
          g.settings.list.forEach(function (e) {
            a.push(e);
          });
          g.settings.list = a;
        }
      });
    },
    deleteClass(id) {
      if (confirm("متأكد من حذف الصف؟")) {
        this.viewSave = true;
        var arr = [];
        this.settings.list.forEach((element) => {
          if (element.id != id) {
            arr.push(element);
          }
        });
        this.settings = {
          list: arr,
        };
      }
    },
    save(l = false) {
      var g = this;
      g.loading = true;
      $.post(api + "/table/settings/save", {
        jwt: user.jwt,
        type: "classes",
        value: JSON.stringify(this.settings),
      })
        .then(function (r) {
          g.loading = false;
          swal.fire("تم بنجاح", "", "success");
          g.viewSave = false;
        })
        .catch(function () {
          swal.fire(
            "حدث خطأ اثناء الاتصال بالخادم",
            "برجاء المحاولة مرة أخرى",
            "warning"
          );
          g.loading = false;
        });
    },
    changed() {
      var a = [];
      this.settings.list.forEach(function (e) {
        a.push(e);
      });
      this.settings.list = a;
    },
  },
  beforeDestroy() {
    if (this.viewSave && confirm("هل تريد حفظ الإعدادات قبل المغادرة؟")) {
      this.save();
    }
  },
};
</script>
